<template>
	<div class="privacies--wrapper">
		<Header></Header>
		<div class="privacies">
			<aside class="privacies--aside">
				<div class="privacies--navigations">
					<div>
						<h1>On this page</h1>
					</div>
					<div class="privacies--navigation">
						<ul class="privacies--lists">
							<li class="privacies--list"><a href="#use">Privacy</a></li>
							<li class="privacies--list"><a href="#service">Policy</a></li>
						</ul>
					</div>
				</div>
			</aside>
			<main class="privacies--main">
				<div class="">
					<div>
						<h1>Privacy and Policy</h1>
						<h4>Last updated on: 30th October 2020</h4>
					</div>
					<div class="privacies--title" id="#privacy">
						<h2>Privacy</h2>
						<p>This data privacy, protection and cookie policy describes your privacy rights regarding Algoforms’ (“we”, “us” or “our”) collection, use, storage, sharing and protection of your personal information. It applies to our website and all related sites, applications, services and tools regardless of how you access or use them.</p>
					</div>
					<div class="privacies--items">
						<div class="privacies--item">
							<h2>Data Privacy</h2>
							<p>This Data Privacy, Protection and Cookie Policy (the “Privacy Policy”) will help you understand how we use your information and what we do with it. However, this Privacy Policy does not apply to services that are not owned or controlled by Algoforms, including third-party websites and the services of other Algoforms’ partners.</p>
							<p>Our goal is to handle personal data provided to us in compliance with applicable data privacy and protection laws. This Privacy Policy applies to all forms of systems, operations and processes within the Algoforms environment that involve the collection, storage, use, transmission and disposal of Personal Information</p>
						</div>
						<div class="privacies--item">
							<h2>Updates, Modifications & Amendments</h2>
							<p>We may need to update, modify or amend our Privacy Policy as our technology evolves and as required by law. We reserve the right to make changes to this Privacy Policy from time to time and notify Users of material changes. The Privacy Policy will apply from the effective date provided on our website.</p>
							<p>We advise that you check this page often, referring to the date of the last modification on the page. If a User objects to any of the changes to this Privacy Policy, the User must cease using this Site, or terminate their account in the event an account has been created.</p>
						</div>
						<div class="privacies--item">
							<h2>Age Restriction</h2>
							<p>Our website and services are only directed to user of legal age. We do not knowingly collect information from children under-age users. If as a parent or guardian, you become aware that your child or ward child has provided us with any information without your consent, please contact us through the details provided in this Privacy Policy.</p>
						</div>
						<div class="privacies--item">
							<h2>Two Factor Authentication (“2FA”)</h2>
							<p>2FA is an additional layer of security we have added to your account. When 2FA is enabled, you will be required to enter a One Time Password (OTP) (which is a verification code we have sent to you for authentication purposes), each time you checkout using Algoforms on a merchant’s website or platform. While we encourage you to enable this feature on every transaction, you may choose to disable the 2FA feature after your initial enrolment by clicking on the toggle button to disable. However, if you choose to disable this feature, you agree that Algoforms shall not be liable for any loss or damages incurred as a result of your action.</p>
						</div>
						<div class="privacies--item">
							<h2>Cookies</h2>
							<p>We use cookies to identify you as a User and make your user experience easier, customise our services, content and advertising; help you ensure that your account security is not compromised, mitigate risk and prevent fraud; and to promote trust and safety on our website. Cookies allow our servers to remember IP addresses, date and time of visits, monitor web traffic and prevent fraudulent activities.</p>
							<p>Our cookies never store personal or sensitive information; they simply hold a unique random reference to you so that once you visit the site we can recognize who you are and provide certain content to you.</p>
							<p>If your browser or browser add-on permits, you have the choice to disable cookies on our website, however this may impact your experience using our website.</p>
						</div>
					</div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
					<div class="privacies--title" id="#policy">
						<h2>Policy</h2>
						<p>If, in our sole discretion, we believe that you may have engaged in any violation of this Acceptable Use Policy.</p>
					</div>
					<div class="privacies--items">
						<div class="privacies--item">
							<h2>Actions by Algoforms</h2>
							<p>We may (with or without notice to you) take such actions as we deem appropriate to mitigate risk to Algoforms and any impacted third parties and to ensure compliance with this Acceptable Use Policy.</p>
						</div>
						<div class="privacies--item">
							<h2>Updates, Modifications & Amendments</h2>
							<p>We may need to update, modify or amend our Acceptable Use Policy at any time. We reserve the right to make changes to this Acceptable Use Policy. We advise that you check this page often, referring to the date of the last modification on the page.</p>
						</div>
					</div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
				</div>
			</main>
		</div>
		<Main></Main>
		<Footer></Footer> 
	</div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/partials/Header.vue";
import Footer from "@/components/partials/Footer.vue";
import Main from "@/components/partials/Main.vue";
export default {
  name: "Privacy",
  components: {
    Header,
    Footer,
    Main,
  },
};
</script>

<style scoped>
/* Privacy */
.privacies {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin-top: 1rem;
}
.privacies--aside {
  display: none;
  grid-column: 1 / 2;
}
.privacies--navigations {
  text-align: right;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.privacies--lists {
  margin-top: 1rem;
}
.privacies--list {
  list-style-type: disc;
  padding-top: 0.75rem;
}
.privacies--main {
  grid-column: 1 / 6;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.privacies--title {
  text-align: center;
  margin-bottom: 0.75rem;
  margin-top: 3rem;
}
.privacies--items {
  text-align: left;
}
.privacies--item {
  margin-block: 2rem;
}
@media only screen and (min-width: 981px) {
  .privacies {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    margin-top: 1rem;
  }
  .privacies--aside {
    display: grid;
    grid-column: 1 / 2;
  }
  .privacies--main {
    grid-column: 2 / 6;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
</style>